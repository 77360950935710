import React from "react"
import { VerticalOverflowContainer } from "../components/OverflowContainer"

function Scratchpad() {
  return (
    <VerticalOverflowContainer style={{ maxHeight: `200px`, maxWidth: `200px`, color: "white", margin: "20vw" }}>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
      <p>aoifwiofjwjfwofijwfw</p>
    </VerticalOverflowContainer>
  )
}

export default Scratchpad
